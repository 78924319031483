import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { GetCurrentAuthState, GetTestsByKioskId } from '../../../../services';
import { NoData } from '../../../../icons/default/NoData';


export const KioskList = ({ }) => {
	const columns = useMemo(() => COLUMNS, [])
	const userData = GetCurrentAuthState()
	const [selectedOption, setSelectedOption] = useState(userData.kiosk[0].kioskId)
	const [data, setData] = useState([]);


	useEffect(() => {
		const fetchData = async () => {
			const rows = userData.kiosk.map((data, i) => ({ 'id': i + 1, ...data }))
			setData(rows);
		};
		fetchData();
	}, [selectedOption]);;
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,

	} = tableInstance
	const { globalFilter, pageIndex } = state

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="card-header">
						<h3 className="card-title">Kiosk Listings</h3>
						<div><GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /></div>
					</div>
					<div className="table-responsive">
						{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className="" >

								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number"
									className="ml-2"
									defaultValue={pageIndex + 1}
									onChange={e => {
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										gotoPage(pageNumber)
									}}
								/>
							</span>
						</div>
						<div className="text-center">
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}
export default KioskList;