import moment from 'moment';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services';
import { LOADING_TOGGLE_ACTION, LOGIN_CONFIRMED_ACTION, LOGIN_FAILED_ACTION, LOGOUT_ACTION, SIGNUP_FAILED_ACTION } from '../reducers/AuthReducer';
import CryptoJS from 'crypto-js';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export function logout(history) {
    localStorage.removeItem('orgDetails');
    localStorage.removeItem('EXP_DATE')
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                const encrypt = CryptoJS.AES.encrypt(JSON.stringify(response.data.data.org), "SWAYAMORGLOGIN").toString()
                saveTokenInLocalStorage(encrypt);
                localStorage.setItem("EXP_DATE", moment().add(3, 'hours').toString())
                dispatch(loginConfirmedAction(encrypt));
                history.push('/');
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(loginFailedAction(error.response.data.message));
                } else {
                    dispatch(loginFailedAction(error.message))
                }
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}