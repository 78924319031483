import React from 'react';


export const GlobalFilter = ({ filter, setFilter }) => {

	return (
		<div>
			{/* <p style={{ marginLeft: "0.417vw" }}> Search : {' '}</p> */}
			{/* <h3 style={{ margin: "auto", position: "absolute", right: "335px", top: "72px", color: "#7E7E7E" }}><BiSearchAlt /> </h3> */}
			<input className="ml-2 input-search form-control" placeholder='Search...'
				value={filter || ''} onChange={(e) => setFilter(e.target.value)}
			/>
		</div>
	)
} 