import React from "react";

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';

import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API

ReactDOM.render(

    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox>
                <BrowserRouter basename="/">
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
