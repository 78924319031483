import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import moment from 'moment/moment';
import { Badge } from "react-bootstrap"
export const COLUMNS = [

	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header: 'Kiosk ID',
		Footer: 'Kiosk ID',
		accessor: 'kioskId',
		Filter: ColumnFilter,
	},
	{
		Header: 'Serial No.',
		Footer: 'Serial No.',
		accessor: 'serialNumber',
		Filter: ColumnFilter,
	},
	{
		Header: 'Location',
		Footer: 'Location',
		accessor: 'locationName',
		Filter: ColumnFilter,
	},
	{
		Header: 'Activated At',
		Footer: 'Activated At',
		accessor: 'createdAt',
		Cell: ({ value }) => { return new Date(value).toLocaleString('en-IN', { timeZone: "Asia/Kolkata" }) },
		Filter: ColumnFilter,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Cell: ({ value }) => {
			{
				return value ? (
					<Badge pill bg="success" className="bg-success">
						<span className="text-uppercase text-white">
							active
						</span>
					</Badge>
				) : (
					<Badge pill bg="danger" className="bg-danger">
						<span className="text-uppercase text-white">
							deactivated
						</span>
					</Badge>
				)
			}
		},
		Filter: ColumnFilter,
	},
	// {
	// 	Header : 'Phone',
	// 	Footer : 'Phone',
	// 	accessor: 'phone',
	// 	Filter: ColumnFilter,
	// },
]

export const GROUPED_COLUMNS = [
	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id'
	},
	{
		Header: 'Name',
		Footer: 'Name',
		columns: [
			{
				Header: 'Kiosk ID',
				Footer: 'Kiosk ID',
				accessor: 'kioskId'
			},
			{
				Header: 'Serial No.',
				Footer: 'Serial No.',
				accessor: 'serialNumber'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header: 'Location',
				Footer: 'Location',
				accessor: 'locationName'
			},
			{
				Header: 'Activated At',
				Footer: 'Activated At',
				accessor: 'createdAt'
			},
			{
				Header: 'Status',
				Footer: 'Status',
				accessor: 'status'
			},
		]
	},
]