import React, { PureComponent } from "react";
import { BarChart, Bar, Tooltip, ResponsiveContainer, XAxis, YAxis, Legend, LabelList, Label } from "recharts";

const data = [
   {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
   },
   {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
   },
   {
      name: "Page C",
      uv: 2000,
      pv: 3000,
      amt: 2290,
   },
   {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
   },
   {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
   },
   {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
   },
   {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
   },
   {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
   },
];

export default class Example extends PureComponent {
   render() {
      return (
         <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
            <BarChart data={data} margin={{
               top: 10,
               bottom: 0,
               left: 0,
               right: 0
            }}>
               <Tooltip cursor={{ fill: "transparent" }} />
               <YAxis />
               <Legend />
               <Bar
                  radius={10}
                  dataKey="pv"
                  height={300}
                  width={400}
                  fill={this.props.color ? this.props.color : "#36C95F"}
               />

            </BarChart>
         </ResponsiveContainer >
      );
   }
}
