import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import moment from 'moment/moment';
export const COLUMNS = [

	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header: 'User name',
		Footer: 'User name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header: 'Gender',
		Footer: 'Gender',
		accessor: 'gender',
		Filter: ColumnFilter,
	},
	{
		Header: 'Address',
		Footer: 'Address',
		accessor: 'address',
		Filter: ColumnFilter,
	},
	{
		Header: 'Phone',
		Footer: 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},
	// {
	// 	Header : 'Country',
	// 	Footer : 'Country',
	// 	accessor: 'country',
	// 	Filter: ColumnFilter,
	// },
	// {
	// 	Header : 'Phone',
	// 	Footer : 'Phone',
	// 	accessor: 'phone',
	// 	Filter: ColumnFilter,
	// },
]

export const GROUPED_COLUMNS = [
	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id'
	},
	{
		Header: 'Name',
		Footer: 'Name',
		columns: [
			{
				Header: 'User name',
				Footer: 'User name',
				accessor: 'name'
			},
			{
				Header: 'Gender',
				Footer: 'Gender',
				accessor: 'gender'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header: 'Address',
				Footer: 'Address',
				accessor: 'address'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header: 'Phone',
				Footer: 'Phone',
				accessor: 'phone'
			},
		]
	},
]