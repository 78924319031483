import React from "react";
import { Link } from "react-router-dom";
import { ComingSoon } from "../../../icons/default/Coming-soon";

export const Shop = () => {
     return (
          <div className="w-full text-center mt-5">
               <ComingSoon height={300} width={300} />
               <p className="text-uppercase fs-20 mt-5">coming soon...</p>
          </div>
     )
}