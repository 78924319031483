import axios from "axios"

export const GetTestsByKioskId = async (kioskId) => {
     const data = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/category/gettestfromkiosk/${kioskId}`, {
     })
     console.log("get the user", data.data.data)
     return data.data.data.result
}

export const GetDashboardCount = async (kioskId) => {
     const data = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/organisation/getcount/${kioskId}`)
     console.log("get the count", data.data.data)
     return data.data.data
}