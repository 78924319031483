import React from 'react';

export const GlobalFilter = ({ filter, setFilter }) => {
	return (
		<div >
			{/* <p style={{ marginLeft: "0.417vw" }}> Search : {' '}</p> */}
			<input className="ml-2 input-search form-control" placeholder='Search...'
				value={filter || ''} onChange={e => setFilter(e.target.value)}
			/>
		</div>
	)
} 