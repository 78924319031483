import { loginConfirmedAction } from "../actions/AuthActions";

var CryptoJS = require("crypto-js");

export const isAuthenticated = (state) => {

    if (state.auth.auth && localStorage.getItem('orgDetails')) {

        const item = localStorage.getItem("orgDetails")
        var bytes = CryptoJS.AES.decrypt(JSON.parse(item), 'SWAYAMORGLOGIN');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        loginConfirmedAction(decryptedData)
        state.auth.auth = decryptedData
        return {
            success: true,
            data: decryptedData
        }
    }
    return {
        success: false,
        data: {}
    };
};
