import axios from 'axios';
import { store, } from '../store/store'

import {
    loginConfirmedAction,
    loginFailedAction,
    logout,
} from '../store/actions/AuthActions';
import CryptoJS from 'crypto-js';
import moment from 'moment';

export function signUp(email, password) {
    //axios call

    const postData = {
        email,
        password,
        returnSecureToken: true,
    };

    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/organisation/orglogin`,
        postData,
    );
}

export function formatError(errorResponse) {
    return errorResponse
}

export function saveTokenInLocalStorage(tokenDetails) {
    const stringed = JSON.stringify(tokenDetails)
    localStorage.setItem('orgDetails', stringed);
}

export const GetCurrentAuthState = () => {
    const stored = store.getState()
    return stored.auth.auth

}

export const FilterSecutiryObject = (decrypted) => {
    // Decrypt
    if (decrypted) {
        var bytes = CryptoJS.AES.decrypt(decrypted, 'SWAYAMORGLOGIN');
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8)
        return JSON.parse(decryptedData)
    } else {
        return null
    }
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export const LogoutUser = (history) => {
    dispatch(logout())
    return history('/login')

}
export function checkAutoLogin(dispatch, history) {
    const token = localStorage.getItem("orgDetails")
    const tokenDetailsString = token
    if (!tokenDetailsString) {
        dispatch(logout(history))
        return
    }
    const currentTime = moment().toString()
    const ExpTime = localStorage.getItem('EXP_DATE')
    if (ExpTime <= currentTime) {
        const errorMessage = formatError("Session expired");
        loginFailedAction(errorMessage)
        dispatch(logout(history))
        return
    }
    dispatch(loginConfirmedAction(token))

}

