
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [

	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header: 'Test name',
		Footer: 'Test name',
		accessor: 'categoryName',
		Filter: ColumnFilter,
	},
	{
		Header: 'Patient name',
		Footer: 'Patient name',
		accessor: 'patientId.name',
		Filter: ColumnFilter,
	},

	{
		Header: 'Report created on',
		Footer: 'Report created on',
		accessor: 'createdAt',
		Cell: ({ value }) => { return new Date(value).toLocaleString('en-IN', { timeZone: "Asia/Kolkata" }) },
		Filter: ColumnFilter,
	},

]

export const GROUPED_COLUMNS = [
	{
		Header: 'Id',
		Footer: 'Id',
		accessor: 'id'
	},
	{
		Header: 'Name',
		Footer: 'Name',
		columns: [
			{
				Header: 'Test name',
				Footer: 'Test name',
				accessor: 'categoryName'
			},
			{
				Header: 'Patient name',
				Footer: 'Patient name',
				accessor: 'patientId.name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header: 'Location',
				Footer: 'Location',
				accessor: 'locationName'
			},
			{
				Header: 'Report created on',
				Footer: 'Report created on',
				accessor: 'createdAt'
			},
		]
	},
]