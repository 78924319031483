import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';

import { NoData } from '../../../../icons/default/NoData';
import { GetCurrentAuthState, GetVcByKioskId } from '../../../../services';
import { GetUsersByKioskId } from '../../../../services/UserService';


export const VideoConsultation = () => {
	const columns = useMemo(() => COLUMNS, [])
	const userData = GetCurrentAuthState()
	const [selectedOption, setSelectedOption] = useState(userData.kiosk[0].kioskId)
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		const fetchData = async () => {
			const result = await GetVcByKioskId(selectedOption);
			console.log(result);
			const rows = result.map((data, i) => ({ 'id': i + 1, ...data }))
			setData(rows);
			setLoading(false)
		};
		fetchData();
	}, [selectedOption]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,

	} = tableInstance

	const handleKioskId = async (e) => {
		setLoading(true)
		setSelectedOption(e.target.value)
		setLoading(false)
	}
	const { globalFilter, pageIndex } = state

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="card-header">
						<h3 className="card-title">Consultations</h3>
						<div className='d-flex flex-row align-items-end justify-content-between' style={{ gap: "30px" }}>
							<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							{userData.kiosk.length >= 2 && (
								<div>
									<div className='w-100 text-right'>
										<p>Selected kiosk ID will display their users.</p>
										<select
											value={selectedOption}
											onChange={e => {
												handleKioskId(e)
											}}
											className="w-100 p-2 rounded-lg">
											{userData.kiosk.map((x, i) => (
												< option key={i} value={x.kioskId} > {x.kioskId} - {x.locationName ? x.locationName : x.location}</option>
											))}
										</select>
									</div>
								</div>
							)}
						</div>
					</div>
					{!loading && data.length ? (
						<div className="table-responsive">
							{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map(column => (
												<th {...column.getHeaderProps()}>
													{column.render('Header')}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >

									{page.map((row) => {
										prepareRow(row)
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
												})}
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ml-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div >
					) : (
						<div className="text-center">
							{loading &&
								<div id="" style={{ height: '60vh', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
									<div className="sk-three-bounce">
										<div className="sk-child sk-bounce1"></div>
										<div className="sk-child sk-bounce2"></div>
										<div className="sk-child sk-bounce3"></div>
									</div>
								</div>}
						</div>
					)}
					{
						!loading && !data.length && (
							<div className="w-full text-center" style={{ marginTop: "4.167vw" }}>
								<NoData height={300} width={300} />
								<p className="fs-20 mt-5">No test data found !</p>
							</div>
						)
					}
				</div>
			</div>
		</>
	)

}
export default VideoConsultation;